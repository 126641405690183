import React, { useState, useEffect } from 'react';
import AuthCardLayout from './AuthCardLayout';
import AddUsernameForm from './AddUsernameForm';

const index = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(async () => {
    if (!loaded) {
      try {
        setLoaded(true);
      } catch (error) {
        console.error(error.message);
      }
    }

    if (!loaded) {
      return <div />;
    }
  }, []);

  return (
    <AuthCardLayout footer={false}>
      <AddUsernameForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default index;

import React, { useState, useEffect } from 'react';
import AuthCardLayout from './AuthCardLayout';
import AddPWDForm from './AddPWDForm';

const index = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const uniqueCode = queryParameters.get('uc');
  const [loaded, setLoaded] = useState(false);

  useEffect(async () => {
    if (!loaded) {
      try {
        setLoaded(true);
      } catch (error) {
        console.error(error.message);
      }
    }

    if (!loaded) {
      return <div />;
    }
  }, []);

  return (
    <AuthCardLayout footer={false}>
      <AddPWDForm layout="card" hasLabel uniqueCode={uniqueCode} />
    </AuthCardLayout>
  );
};

export default index;

import React from 'react';
import AuthCardLayout from './AuthCardLayout';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  return (
    <AuthCardLayout footer={false}>
      <ForgotPasswordForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default ForgotPassword;
